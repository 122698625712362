import React,{useReducer} from 'react';
import './App.css';
import SignIn from './components/SignIn';
import { Route, Switch,Redirect,BrowserRouter } from "react-router-dom";
import HomePage from './components/HomePage';
import ReactNotifications from 'react-notifications-component';
export const access_tokenContext = React.createContext();

function App() {

  var initialState = "NoToken";
  const reducer = (state, action) => {
    switch (action.case) {
      case 'signIn':
        return action.token ;
      default:
        return state;
    }
  }

  const [access_token,dispatch] = useReducer(reducer,initialState);
 

  return (
    <access_tokenContext.Provider value={{access_tokenState:access_token,access_tokenDispatch:dispatch}}>
     
    <div class="App">
      <ReactNotifications/>
      <BrowserRouter>
     <Switch>
      <Route exact path="/" component={SignIn} />
      
      <Route path="/Home"  render={()=>(
               access_token === "NoToken" ? 
               (alert("Authentication Expired !\n"+"You need to logged in First!"), (<Redirect to="/"/>)): (<HomePage/>)
            )} />
       <Route path="*" component={() => <Redirect to="/" />} /> 
    </Switch>
     </BrowserRouter>
    </div>
    </access_tokenContext.Provider>
  );
}

export default App;
