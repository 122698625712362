import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBarComponent from './AppBarComponent';
import CreateData from './CreateData';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import WebIcon from '@material-ui/icons/Web';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
    
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontFamily: 'Montserrat',
    margin:'2%',
    marginBottom:'200px',
    
  },
  footer:{
    backgroundColor:'#8d57cf',
    paddingTop:'20px',
  
    position: 'fixed',
  left: 0,
  bottom: 0,
    height:'120px',
    width:'100%'
  },
  Icons:{
    color:'white'
  }
}));

const HomePage = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [search,setSearch]=useState('');
    
    return (
     
        <div className={classes.root} >
            <AppBarComponent onChange={value=>setSearch(value)}/>
         
  
            <div className={classes.paper}>
    
            <CreateData searchdata={search}  />
            </div>
             <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        console.log('value is ',value)
      }}
      showLabels
      className={classes.footer}
      
      
    >

    <a href="https://www.eleoshospice.com/"  style={{color:'white',margin:'10px',textAlign:'center'}}>
      <WebIcon style={{color:'white'}} />
      <br/>
      <p>Web</p>
    </a>
    <a href="https://www.facebook.com/eleoshospicecarefw/"  style={{color:'white',margin:'10px',textAlign:'center'}}>
      <FacebookIcon style={{color:'white'}} />
      <br/>
      <p>Facebook</p>
    </a>
    <a href="https://www.eleoshospice.com/"  style={{color:'white',margin:'10px',textAlign:'center'}}>
      <TwitterIcon style={{color:'white'}} />
      <br/>
      <p>Twitter</p>
    </a>
    <a href="https://www.eleoshospice.com/"  style={{color:'white',margin:'10px',textAlign:'center'}}>
      <InstagramIcon style={{color:'white'}} />
      <br/>
      <p>Instagram</p>
    </a>
  

  
    </BottomNavigation> 
            
    
    </div>
    );
};

export default HomePage;

