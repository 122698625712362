import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
const aws = require('aws-sdk');
var dateFormat = require("dateformat");

const s3 = new aws.S3(
          { accessKeyId: 'AKIA2EMMFZU3QHL375A7',
            secretAccessKey: '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',
            region: 'us-east-2'
          }
);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const CreateData = (props) => {
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);
    
    
  
    
    
    const useStyles = makeStyles((theme) =>({
      table: {
        minWidth: 700,
        
    
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight:'100%',
        maxWidth:'300',
        PaddingTop:'5px',
        textAlign:'centre'
        
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
       
      }
    }));
    const classes = useStyles();
    const [data,setData]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [image,setImage]=useState('');
    const [loading,setLoading]=useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
     useEffect(() => {
    
      async function fetchData() {
       
        const res = await fetch("https://refer-api-stg.novahawk.cloud/refer-api-lambda/1.3/api/facesheets");
        res
          .json()
          .then(res => {console.log(JSON.stringify(res.Items));setData(res.Items)})
          .catch(err => console.log('error is '+err));
          
      }
     
     fetchData();
    
   
    },[]);

    //Code to onClick of download button
  function downloadFun(ImageId,url){
     setLoading(true);
   // alert(ImageId+""+url);
     var key=ImageId;
      var getParams = {
      Bucket: 'eleos-facesheet-stg', // your bucket name,
      Key: key // path to the object you're looking for
      
     
      }
      s3.getObject(getParams,function(err,data){
        if(err)
        {   store.addNotification({
          title: 'Error in download',
          message: 'Download Unsuccessfull'+err,
          type: 'danger',                         // 'default', 'success', 'info', 'warning'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 3000
          }
        })
         setLoading(false);
        }
        else{
               console.log('data to download ',data.Body);
               let blob=new Blob([data.Body], {type: data.ContentType});
                 let link=document.createElement('a');
                 link.href=window.URL.createObjectURL(blob);
                 
                 link.download=url;
                 link.setAttribute('download', 'Facesheet.jpg');
                 link.click();
                 setLoading(false);
                 store.addNotification({
                  title: 'Success',
                  message: 'Download successfully',
                  type: 'default',                         // 'default', 'success', 'info', 'warning'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 3000
                  }
                })

                 
        }
      })
 
  }
  //Code to onClick of View button
  function viewFun(ImageId,url){
    setLoading(true);
    console.log('view Runs Now ',url);
     console.log('DOwnLoad Runs Now ');
     var key=ImageId;
      var getParams = {
      Bucket: 'eleos-facesheet-stg', // your bucket name,
      Key: key // path to the object you're looking for
      }
      s3.getObject(getParams,function(err,data){
        if(err)
        {   store.addNotification({
          title: 'Error !',
          message: 'View Unsuccessfull',
          type: 'danger',                         // 'default', 'success', 'info', 'warning'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 3000
          }
        })
        
        }
        else{
               console.log(data.Body);
               let blob=new Blob([data.Body], {type: data.ContentType});
                 let link=document.createElement('a');
                 link.href=window.URL.createObjectURL(blob);
                 setImage(link.href);
                setOpen(true);
                 setLoading(false);
                 
             
        }
      })
    
    
    
      
  }
 
  



  const handleClose = () => {
    setOpen(false);
  };
 
    return (
      <div>
      
         <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
             
            <h2 id="transition-modal-title" style={{fontFamily:'serif',fontSize:'28px',spacing:'1'}}> <Button style={{marginRight:'15%',marginBottom:'4px',width:'2px'}} onClick={handleClose}><CancelIcon fontSize="4px" style={{color:'blue'}}></CancelIcon></Button> FaceSheet</h2>
            <img style={{height:'500px',width:'330px',opacity:'1'}} src={image} alt="No Image "/>
          </div>
        </Fade>
      </Modal>   
       {loading?<LinearProgress />
       :
       <TableContainer  component={Paper} >
          
          <Table className={classes.table} aria-label="customized table" >
            <TableHead>
              <TableRow>
                <StyledTableCell  align="center">Image ID</StyledTableCell>
                <StyledTableCell align="center">Comments</StyledTableCell>
                <StyledTableCell align="center">Uploaded By</StyledTableCell>
                <StyledTableCell align="center">Upload Time</StyledTableCell>
                <StyledTableCell align="center">Referal Source</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            
            {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => (
           
            (props.searchdata!=''&&props.searchdata==row.UploadedBy)?
             
            <TableRow key={row.name}  style={{backgroundColor:'aqua',overflow:'auto',maxHeight:'100%'}}>

      
            <TableCell component="th" style={{ width: 160 }}  scope="row" align="center">
              {row.ImageId}
            </TableCell>
            <TableCell style={{ width: 160 }} align="center">
              {row.Comments}
            </TableCell>
            <TableCell style={{ width: 160 }} align="center">
              {row.UploadedBy}
            </TableCell>
            <TableCell style={{ width: 160 }} align="center">
              {row.UploadTime}
            </TableCell>
            <TableCell style={{ width: 160 }} align="center">
              {row.ReferalSource}
            </TableCell>
            <TableCell style={{ width: 160 }} align="center">
            <Button style={{border:'none'}}  onClick={()=>viewFun(row.ImageId,row.S3ImagePath)}>
                  <RemoveRedEyeOutlinedIcon/>
                </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button style={{border:'none'}} onClick={()=>downloadFun(row.ImageId,row.S3ImagePath)}>
                  <GetAppIcon/>
                  </Button>
            </TableCell>
          </TableRow>
            
            :
            
        
           <TableRow key={row.name}>

         
           <TableCell component="th" scope="row" style={{ width: 160 }}  align="center">
             {row.ImageId}
           </TableCell>
           <TableCell style={{ width: 160 }} align="center">
             {row.Comments}
           </TableCell>
           <TableCell style={{ width: 160 }} align="center">
             {row.UploadedBy}
           </TableCell>
           <TableCell style={{ width: 200 }} align="center">
             {dateFormat(row.UploadTime,"h:MM:ss TT,  dddd, mmmm dS, yyyy") }
           </TableCell>
           <TableCell style={{ width: 160 }} align="center">
             {row.ReferalSource}
           </TableCell>
           <TableCell style={{ width: 160 }} align="center">
           <Button style={{border:'none'}}  onClick={()=>viewFun(row.ImageId,row.S3ImagePath)}>
                 <RemoveRedEyeOutlinedIcon/>
               </Button>
                 &nbsp;&nbsp;&nbsp;
                 <Button style={{border:'none'}} onClick={()=>downloadFun(row.ImageId,row.S3ImagePath)}>
                 <GetAppIcon/>
                 </Button>
           </TableCell>
         </TableRow>
           )
          
          )}
           
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        
             
           
            </TableBody>
            <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
          </Table>
        </TableContainer>
             }
         
         
        
      </div>
       
    );
};

export default CreateData;